import React from "react"
import BackgroundImage from "gatsby-background-image"

export default props => (
  <section className="hero-banner">
    <BackgroundImage
      Tag="div"
      className="hero-banner__inside"
      fluid={props.bgImage}
      backgroundColor={`#333333`}
    >
    </BackgroundImage>
    <div className="hero-banner__content">
        <h1>{props.title}</h1>
        <h2>{props.subTitle}</h2>
        <a href={props.link}>{props.linkTitle}</a>
      </div>
  </section>
)
